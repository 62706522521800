import type { NextPage } from 'next';

import homePageServerProps from '@oneflare-server-side-props/_homePage';
import OneflareHome from 'pages/oneflare.com.au/Home';
import type { HomePageProps } from 'types/oneflare.com.au/home';

const HomePage: NextPage = (props: HomePageProps) => {
  return <OneflareHome {...props} />;
};

export { HomePage as default,  homePageServerProps as getServerSideProps };
