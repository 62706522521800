import { memo } from 'react';
import Network from 'shared/components/Network';
import PropTypes from 'prop-types';
import Banner from 'shared/components/Banner/Banner';
import FreeQuotesForm from 'shared/components/FreeQuotesForm/FreeQuotesForm';
import {
  GetFreeQuotesWrapperStyled,
  HomepageHeroStyled,
  NetworkStyled
} from './styled/HomepageHero';

const propTypes = {
  randomNumber: PropTypes.number.isRequired
};

const HomepageHero = ({ randomNumber }) => {
  return (
    <HomepageHeroStyled $rndNumber={randomNumber}>
      <GetFreeQuotesWrapperStyled>
        <FreeQuotesForm />
      </GetFreeQuotesWrapperStyled>
      <Banner
        isOneflareHomePage
        group="Oneflare"
        url="https://cdn.oneflare.com/static/client/oneflare/airtasker-oneflare-banner-lossless.svg"
      />
      <NetworkStyled>
        <Network kind="nolink" />
      </NetworkStyled>
    </HomepageHeroStyled>
  );
};
HomepageHero.propTypes = propTypes;

export default memo(HomepageHero);
