import { styled } from 'styled-components';
import H2 from '@oneflare/flarekit/lib/components/H2';
import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';

export const BottomBannerStyled = styled.div`
  display: flex;
  width: 100%;
  bottom: 0;
  position: absolute;
  padding: 11px 16px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  transform: ${({ show }) => (show ? 'none' : 'translateY(100%)')};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transition: ${({ show }) => (show ? 'all 0.5s ease-in' : 'linear')};
`;

export const BottomBannerTextStyled = styled(H2)`
  font-size: 13px;
  padding-right: 16px;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
`;

export const ButtonLinkStyled = styled(ButtonLink)`
  min-width: 136px;
`;
