
export const homePageHowItWorksCustomer = [
  {
    mobileImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-customer-step-1-mobile.svg',
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-customer-step-1.svg',
    title: 'Tell us what you need done',
    description: 'Answer a few simple questions about your job to receive competitive quotes.',
    widthFix: '70%'
  },
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-customer-step-2.svg',
    title: 'Compare your quotes',
    description: 'Chat with businesses who respond to your job and discuss the finer details.'
  },
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-customer-step-3.svg',
    title: 'Hire the right business',
    description: 'Select the best business for the job and leave a review once your job is complete.'
  }
];

export const homePageHowItWorksBusiness = [
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-business-step-1.svg',
    title: 'Access 1,000s of customers',
    description: 'Get more leads and take advantage of the 12,000+ customers who post a job on Oneflare every week.',
    mobileImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-business-step-1.svg'
  },
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-business-step-2.svg',
    title: 'Pick the jobs you want',
    description: 'Browse through all the open jobs for the type of jobs you like and the area you work.'

  },
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-business-step-3.svg',
    title: 'Fill your schedule',
    description: 'Grow your business from scratch, or fill in the gaps. Pick and choose which customers to connect with.'
  }
];
