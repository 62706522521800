import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Marquee from 'react-fast-marquee';

import {
  MarqueeWrapper,
  WhatsLiveWrapper,
  WhatsLiveTitleStyled,
  JobStyled,
  AvatarStyled,
  JobCategoryStyled,
  JobStyleHeader,
  JobUsersName,
  JobUsersLocation,
  JobDescription,
  JobPosted
} from './styled/WhatsLive';
import { avatarBackground } from './utils';

const WhatsLive = ({ whatsLiveData }) => {
  const marqueSpeed = 14;
  if (isEmpty(whatsLiveData)) return null;
  const dataMidPointIndex = whatsLiveData.length / 2;
  const [homePublicJobsFirstHalf, homePublicJobsSecondHalf] = [
    whatsLiveData.slice(0, dataMidPointIndex),
    whatsLiveData.slice(dataMidPointIndex, whatsLiveData.length)
  ];

  return (
    <WhatsLiveWrapper className="e2e-hide">
      <WhatsLiveTitleStyled>
        See what’s live
      </WhatsLiveTitleStyled>
      {!isEmpty(homePublicJobsFirstHalf) && <MarqueeWrapper data-testid="left-carousel-wrapper">
        <Marquee
          direction="left"
          className="left"
          speed={marqueSpeed}
        >
          {homePublicJobsFirstHalf.map((job) => <JobCard job={job} key={job.jobId} />)}
        </Marquee>
      </MarqueeWrapper>}
      {!isEmpty(homePublicJobsSecondHalf) && <MarqueeWrapper data-testid="right-carousel-wrapper">
        <Marquee
          className="right"
          direction="right"
          speed={marqueSpeed}
        >
          {homePublicJobsSecondHalf.map((job) => <JobCard job={job} key={job.jobId} />)}
        </Marquee>
      </MarqueeWrapper>}
    </WhatsLiveWrapper>
  );
};

const JobCard = ({ job }) => {
  const { censoredDescription, category, createdAt, userSimpleInitials, userFirstNameLastInitial, suburb, state } = job;
  return (
    <JobStyled data-testid="job-card">
      <JobCategoryStyled>{category?.name}</JobCategoryStyled>
      <JobStyleHeader>
        <AvatarStyled
          avatarText={userSimpleInitials}
          avatarSize={{ default: '40px' }}
          avatarTextBackground={avatarBackground}
        />
        <JobUsersName>{userFirstNameLastInitial}</JobUsersName>
        <JobUsersLocation>{`${suburb || ''}, ${state || ''}`}</JobUsersLocation>
      </JobStyleHeader>
      {censoredDescription && <JobDescription>{censoredDescription}</JobDescription>}
      <JobPosted>{`Posted ${DateTime.fromISO(createdAt).toRelative()}`}</JobPosted>
    </JobStyled>
  );
};

WhatsLive.propTypes = {
  whatsLiveData: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.shape({
      name: PropTypes.string
    }),
    censoredDescription: PropTypes.string,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    jobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    state: PropTypes.string,
    suburb: PropTypes.string,
    userFirstNameLastInitial: PropTypes.string,
    userSimpleInitials: PropTypes.string
  })).isRequired
};

export default memo(WhatsLive);
