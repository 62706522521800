import { styled } from 'styled-components';

export const NetworkWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const NetworkContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 58px;
    
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const NetworkList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 16px 20px;
  align-items: center;
  justify-content: left;
  width: 970px;
    
  .primaryFill {
    fill: ${({ theme }) => theme.color.silver};
  }

  .backgroundFill {
    fill: ${({ theme }) => theme.color.white};
  }
  
  svg {
    width: 100%;
  }
`;

export const NetworkTitle = styled.li`
  color: ${({ theme: { color } }) => color.spaceGrey};
  font-family: ${({ theme: { font } }) => font.primaryRegular};
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
  position: relative;
  top: 3px;
`;

export const NetworkLogo = styled.li`
  margin: 0 16px;
`;
