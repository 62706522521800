import PropTypes from 'prop-types';

import AsyncSVG from 'shared/components/ClientSideAsyncSvg';

import {
  NetworkContainer,
  NetworkList,
  NetworkLogo,
  NetworkTitle,
  NetworkWrapper
} from './styled/Network';

const defaultProps = {
  className: null,
  kind: 'default'
};

const propTypes = {
  className: PropTypes.string,
  kind: PropTypes.string
};

const links = [
  {
    title: 'Domain', icon: 'domain', href: 'https://www.domain.com.au', width: 78, height: 18
  },
  {
    title: 'Commercial Real Estate', icon: 'commercial-real-estate', href: 'https://www.commercialrealestate.com.au', width: 81, height: 19
  },
  {
    title: 'All Homes', icon: 'all-homes', href: 'https://www.allhomes.com.au', width: 80, height: 23
  },
  {
    title: 'The Sydney Morning Herald', icon: 'sydney-morning-herald', href: 'https://www.smh.com.au', width: 159, height: 16
  },
  {
    title: 'The Age', icon: 'the-age', href: 'https://www.theage.com.au', width: 141, height: 15
  },
  {
    title: 'Financial Review', icon: 'financial-review', href: 'https://www.afr.com/', width: 130, height: 15
  }
];

const Icon = ({
  link
}) => (
  <AsyncSVG
    path={`https://cdn.oneflare.com/static/icons/${link.icon}.svg`}
    width={link.width}
    height={link.height}
  />
);

Icon.propTypes = {
  link: PropTypes.shape({
    icon: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

const Network = ({ className, kind }) => (
  <NetworkWrapper>
    <NetworkContainer className={className}>
      <NetworkList>
        <NetworkTitle>Our Network</NetworkTitle>
        {links.map((link) => {
          return (
            <NetworkLogo key={link.title}>
              {kind === 'nolink' ? (
                <Icon link={link} />
              ) : (
                <a href={link.href} title={link.title}>
                  <Icon link={link} />
                </a>
              )}
            </NetworkLogo>
          );
        })}
      </NetworkList>
    </NetworkContainer>
  </NetworkWrapper>
);

Network.defaultProps = defaultProps;
Network.propTypes = propTypes;

export default Network;
